<template>
  <modal
    name="contractor-modal"
    :resizable="true"
    :height="'auto'"
    :width="'50%'"
    :scrollable="true"
  >
   <div class="row bg-secondary mt-1 ml-0">
      <div class="col-6">
        <h5 class="float-left align-middle text-white mt-2">Edytuj Kontrahenta</h5>
        
      </div>
    </div>
    <div id="modal-content" class="row mb-5">
      <div class="col-12 p-5">
        <div class="row">
          <div class="col-12">
            <h3>{{contractor.name}}</h3>
            <hr>
          </div>
          <div class="col-6">
            <div class="form-group" @submit.prevent>
              <small for="brand">Imię i nazwisko / Nazwa</small>
              <input
                id="brand"
                class="form-control form-sm my-1"
                v-model="contractor.name"
                placeholder="Nazwa"
              />
              <small>Adres</small>
              <input
                class="form-control my-1"
                v-model="contractor.address"
                placeholder="Adres"
              />
              <small>Numer Telefonu</small>
              <input
                class="form-control my-1"
                v-model="contractor.phoneNumber"
                placeholder="Numer Telefonu"
              />
              <small>Email</small>
              <input
                class="form-control my-1"
                v-model="contractor.email"
                placeholder="Email"
              />
            </div>
          </div>
          <div class="col-6">
            <div v-if="contractor.type==='company'">
            <small>NIP</small>
            <input
              class="form-control my-1"
              v-model="contractor.nip"
              placeholder="NIP"
            />
            <small>Osoba kontaktowa</small>
            <input
              class="form-control my-1"
              v-model="contractor.contactPerson"
              placeholder="Osoba Kontaktowa"
            />
            </div>
            <div>
              <small>lista instalacji</small>
              <div v-for="item in contractorInstalations" :key="item.id">
                montaz:{{item.date}} 
              </div>
            </div>



            

          
           
          </div>
        </div>   
        <small>Uwagi</small>  
       <textarea
          class="form-control my-1"
          v-model="contractor.notice"
          placeholder="Komentarz"
        />
        <!-- <input class="form-control my-1" v-model="car.pt" placeholder="Przegląd techniczny" /> -->
       
      <!-- <div class="col-6">
        <div class="p-5">
     
        </div>
      </div> -->
      <!-- <div class="col-6">
         
      </div> -->
<hr>
          <button v-if="contractor.type === 'company'"
            id="editCompanyButton"
            style="width: 100%; height: 65px"
            class="btn btn-info btn-sm mt-1"
            @click="updateCompany"
          >
            Edytuj Firmę
          </button>
           <button v-else
            id="editCustomerButton"
            style="width: 100%; height: 65px"
            class="btn btn-info btn-sm mt-1"
            @click="updateCustomer"
          >
            Edytuj Kontrahenta
          </button>
       
          <button
          
            style="width: 100%; height: 65px"
            class="btn btn-danger btn-sm mt-1"
            @click="deleteContractor"
          >
            Usuń Kontrahenta
          </button>
          <!-- <button @click="contractorInstalations">test</button> -->
     
      </div>
      <!-- {{ car }} -->
    </div>
  </modal>
</template>
<script>
import { mapState } from "vuex";
export default {
    
   props: ["contractor"],
        

  data() {
    return {
      contractorChecked:{}
    };

    

    
  },
  computed: {
    ...mapState(["userProfile","installations", "contractorUpdated"]),
    contractorInstalations(){
      return this.installations.filter((item) => {
        return item.contractor.id.includes(this.contractor.id);
      });
    },
    contractorAddress(){
      if(!this.contractor.address){
        return "-"
      } else {
        return this.contractor.address
      }
    },
    contractorPhoneNumber(){
      if(!this.contractor.phoneNumber){
        return "-"
      } else {
        return this.contractor.phoneNumber
      }
    }
    ,
    contractorEmail(){
      if(!this.contractor.email){
        return "-"
      } else {
        return this.contractor.email
      }
    },
    test(){
      
      return this.contractor.id
    }
    ,
    contractorNip(){
      if(!this.contractor.nip){
        return "-"
      } else {
        return this.contractor.nip
      }
    }
   
    
  },
  
  methods: {
    //  contractorInstalations(){
    //   this.installations.filter((item) => {
    //     item.contractor.id.includes(this.contractor.id);
    //     console.log("itemid-> ", item.contractor.id)
    //     console.log("contractorid-> ", this.contractor.id)
    //   });
    // }, 
    updateCompany(){
      console.log(this.contractorNip)
      this.$store
        .dispatch("updateCompany", {
          id: this.contractor.id,
          address: this.contractor.address,
          notice: this.contractor.notice,
          email: this.contractorEmail,
          contactPerson: this.contractor.contactPerson,
          name: this.contractor.name,
          nip: this.contractorNip,
          phoneNumber: this.contractorPhoneNumber,
        })
        .then(() => {
          
            let button = document.getElementById("editCompanyButton");
            button.style.background = "#d4edda";
            button.style.color = "#155724";
            button.innerText = "Firma została edytowana";
          
          setTimeout(() => {
            let button = document.getElementById("editCompanyButton");
            button.style.background = "";
            button.style.color = "";
            button.innerText = "Edytuj Kontrahenta";
            this.carUpdated = null;
          }, 2000);
          setTimeout(() => {
            this.$emit("closeModal");
          }, 1000);
        });
    },
    updateCustomer(){
      console.log()
      this.$store
        .dispatch("updateCustomer", {
          id: this.contractor.id,
          address: this.contractor.address,
          notice: this.contractor.notice,
          email: this.contractorEmail,
          name: this.contractor.name, 
          phoneNumber: this.contractorPhoneNumber,
      
        })
        .then(() => {
          
            let button = document.getElementById("editCustomerButton");
            button.style.background = "#d4edda";
            button.style.color = "#155724";
            button.innerText = "Kontahent został edytowany";
          
          setTimeout(() => {
            let button = document.getElementById("editCustomerButton");
            button.style.background = "";
            button.style.color = "";
            button.innerText = "Edytuj Kontrahenta";
            this.carUpdated = null;
          }, 2000);
          setTimeout(() => {
            this.$emit("closeModal");
          }, 1000);
        });
    },

    deleteContractor(){
       if (
        confirm("Czy jesteś pewien ze chcesz usunąć kontrahenta?")
      ) {
        this.$store.dispatch("deleteContractor", {
          id: this.contractor.id,
        });
      } else {
        console.log("Contractor Deleted.");
      }
    }


  }
};
</script>
<style>
</style>